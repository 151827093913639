import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 777.000000 777.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,777.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M3600 6594 c-25 -2 -103 -9 -175 -15 -788 -66 -1549 -321 -2140 -719
-658 -443 -1073 -1044 -1169 -1693 -21 -141 -21 -415 0 -556 57 -391 219 -748
492 -1087 102 -126 326 -346 462 -453 621 -491 1449 -797 2370 -877 237 -21
813 -15 1020 10 787 95 1438 320 2000 691 206 136 347 250 511 414 158 159
233 249 344 416 246 368 365 749 365 1165 0 302 -69 602 -200 875 -315 652
-937 1177 -1792 1511 -385 150 -790 246 -1274 299 -131 15 -708 28 -814 19z
m625 -54 c640 -43 1245 -199 1775 -458 191 -94 491 -278 566 -349 16 -15 31
-28 34 -28 13 0 234 -209 344 -323 234 -244 439 -578 535 -868 80 -242 107
-433 98 -689 -10 -279 -73 -527 -197 -780 -116 -239 -246 -419 -455 -634 -450
-462 -1068 -799 -1805 -986 -925 -235 -1953 -196 -2845 108 -195 66 -302 110
-500 206 -405 197 -728 428 -1015 729 -176 184 -203 216 -278 331 -167 257
-273 530 -318 826 -25 166 -15 486 20 652 133 628 558 1184 1226 1607 590 374
1374 612 2170 659 132 8 507 6 645 -3z"
          />
          <path
            d="M3528 6470 c-855 -63 -1642 -337 -2245 -782 -166 -122 -392 -338
-516 -493 -421 -525 -576 -1133 -437 -1721 83 -353 265 -687 534 -979 85 -92
275 -265 291 -265 4 0 15 -8 24 -18 18 -19 293 -203 411 -274 122 -74 335
-181 485 -243 1214 -505 2715 -462 3858 111 819 411 1367 1065 1486 1775 80
476 -20 941 -300 1389 -39 63 -81 125 -93 138 -11 13 -19 24 -16 25 14 5 -129
176 -235 283 -252 253 -528 448 -880 621 -446 219 -937 356 -1500 418 -165 19
-695 28 -867 15z m892 -155 c746 -92 1375 -318 1885 -678 117 -82 112 -78 320
-284 236 -233 386 -437 502 -679 276 -581 234 -1202 -119 -1764 -65 -102 -175
-244 -232 -299 l-35 -34 -303 17 c-167 9 -413 24 -548 32 -135 8 -382 19 -550
23 -168 5 -316 14 -330 19 -23 10 -251 25 -585 39 l-140 5 -42 -31 c-59 -43
-77 -81 -75 -164 1 -91 22 -125 87 -150 57 -21 26 -22 880 33 88 6 419 26 735
45 316 20 629 39 695 44 66 4 124 6 128 3 4 -2 -26 -27 -67 -55 -41 -29 -114
-83 -161 -122 -135 -109 -248 -187 -410 -282 -1091 -637 -2641 -729 -3880
-231 -340 137 -641 311 -910 527 -65 53 -142 107 -169 121 -75 38 -73 54 5 46
58 -5 336 -23 1369 -86 179 -11 393 -24 475 -30 543 -35 511 -34 547 -12 45
28 80 92 79 145 0 72 -31 148 -71 175 -41 28 -36 28 -670 -8 -195 -11 -474
-27 -620 -35 -146 -9 -344 -20 -440 -25 -96 -6 -290 -17 -430 -25 -377 -22
-336 -27 -419 55 -38 39 -67 70 -64 70 3 0 -3 8 -13 17 -68 62 -201 276 -270
433 -54 123 -106 293 -130 425 -23 125 -26 451 -6 565 77 435 277 806 617
1145 567 566 1414 926 2395 1020 200 19 783 13 970 -10z m-3390 -980 c-24 -25
-46 -45 -49 -45 -3 0 15 20 39 45 24 25 46 45 49 45 3 0 -15 -20 -39 -45z
m2464 -2906 c-27 -30 -37 -22 -12 12 12 16 24 25 26 19 2 -6 -4 -20 -14 -31z
m3266 8 c0 -2 -15 -16 -32 -33 l-33 -29 29 33 c28 30 36 37 36 29z"
          />
          <path
            d="M2411 5531 c-11 -11 -23 -35 -26 -53 -11 -59 -1 -474 12 -489 9 -11
38 -13 145 -9 l133 5 3 113 3 112 44 0 c24 0 51 -3 60 -6 14 -6 14 -8 -2 -26
-16 -18 -18 -50 -21 -377 l-3 -358 -32 -14 c-17 -8 -37 -25 -44 -39 -13 -27
-17 -235 -4 -279 l6 -24 280 2 c154 1 280 3 281 4 1 1 4 76 5 167 l4 165 -25
-1 c-14 -1 -35 -5 -47 -9 l-23 -7 0 401 0 401 44 0 c24 0 51 -3 60 -6 14 -5
14 -8 1 -27 -19 -28 -21 -180 -2 -193 6 -5 71 -7 142 -4 l130 5 5 270 c3 155
2 275 -3 283 -7 9 -131 12 -558 12 -530 0 -550 -1 -568 -19z m259 -284 c0 -2
-10 -12 -21 -23 -22 -19 -22 -19 -10 4 10 18 31 31 31 19z m470 -12 c-7 -9
-15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z"
          />
          <path
            d="M3761 5055 c-58 -18 -107 -67 -128 -129 -16 -45 -18 -90 -18 -366 0
-278 2 -320 18 -361 20 -53 45 -79 79 -84 12 -1 43 -8 68 -14 31 -9 123 -11
285 -9 219 3 243 5 274 23 62 37 72 60 83 195 7 99 7 125 -4 131 -20 13 -283
11 -312 -2 -15 -7 -28 -23 -31 -40 -5 -21 -14 -31 -33 -36 -15 -3 -37 -12 -49
-20 -21 -14 -21 -14 -25 62 -2 42 -1 79 1 81 2 2 105 4 228 4 l224 0 -4 218
c-3 119 -8 233 -12 252 -10 48 -49 86 -100 99 -65 16 -488 13 -544 -4z m184
-235 c-10 -11 -20 -18 -23 -15 -7 6 18 35 31 35 5 0 2 -9 -8 -20z m149 -29
c10 -16 -28 -71 -54 -78 -14 -3 -35 -12 -47 -20 -21 -14 -21 -13 -25 42 -1 31
-1 59 1 61 9 8 120 4 125 -5z m-147 -278 c-9 -9 -19 -14 -22 -11 -3 3 2 13 11
22 9 9 19 14 22 11 3 -3 -2 -13 -11 -22z m393 -338 c-7 -8 -17 -15 -23 -15 -5
0 -2 9 8 20 21 23 35 19 15 -5z"
          />
          <path
            d="M4679 5054 c-57 -17 -113 -75 -131 -136 -9 -31 -13 -127 -13 -353 0
-411 3 -422 133 -457 54 -14 109 -18 292 -18 238 0 282 6 328 45 36 30 49 79
56 207 5 81 3 97 -10 102 -25 10 -280 7 -307 -3 -16 -6 -28 -21 -32 -40 -5
-23 -14 -33 -33 -37 -15 -4 -37 -12 -49 -20 -21 -13 -21 -13 -25 62 -2 42 -1
78 1 80 2 2 105 4 228 4 l223 0 0 198 c-1 286 -19 344 -118 371 -58 17 -487
13 -543 -5z m186 -234 c-10 -11 -20 -18 -23 -15 -7 6 18 35 31 35 5 0 2 -9 -8
-20z m149 -29 c10 -16 -28 -71 -54 -77 -14 -4 -35 -12 -47 -20 -21 -13 -21
-12 -25 42 -1 31 -1 58 1 60 9 8 120 4 125 -5z m-140 -265 c-3 -8 -12 -17 -20
-20 -18 -7 -18 -1 2 18 19 20 25 20 18 2z m386 -351 c-7 -8 -17 -15 -23 -15
-5 0 -2 9 8 20 21 23 35 19 15 -5z"
          />
          <path
            d="M1965 4455 c-54 -19 -108 -81 -131 -152 -18 -54 -19 -94 -19 -553 0
-449 2 -500 18 -546 38 -110 89 -125 401 -122 l221 3 5 255 c3 140 1 261 -4
268 -6 8 -40 12 -111 12 -140 0 -149 -7 -153 -122 l-4 -85 -36 -14 c-21 -8
-40 -15 -44 -17 -5 -2 -8 168 -8 377 l0 381 64 0 63 0 -19 -40 c-21 -41 -18
-90 5 -107 12 -9 221 -2 231 7 8 8 9 447 1 459 -10 17 -430 14 -480 -4z m125
-269 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z"
          />
          <path
            d="M5689 4291 c-23 -18 -24 -27 -27 -143 -3 -115 -5 -125 -29 -149 -24
-26 -24 -29 -17 -135 8 -123 15 -144 49 -144 24 0 24 -1 9 -35 -11 -27 -14
-90 -13 -302 0 -147 4 -277 8 -288 8 -19 16 -20 157 -16 82 2 150 4 151 5 1 1
3 60 3 131 l2 130 -31 -1 -31 -2 0 194 0 194 30 0 30 0 2 120 c0 66 1 126 2
133 1 13 -18 14 -47 2 -18 -7 -19 0 -16 147 1 85 -1 159 -5 166 -5 8 -40 12
-105 12 -82 0 -103 -3 -122 -19z m216 -531 c-10 -11 -21 -17 -24 -14 -3 3 3
12 13 20 28 20 32 17 11 -6z"
          />
          <path
            d="M2630 3999 c-41 -17 -79 -59 -94 -108 -23 -72 -27 -166 -18 -446 9
-272 13 -297 58 -336 26 -21 53 -24 246 -24 l167 0 31 30 c51 49 55 80 55 415
0 236 -3 315 -14 350 -8 25 -14 49 -13 53 5 18 -22 50 -53 63 -41 17 -325 19
-365 3z m120 -244 c-7 -9 -15 -13 -19 -10 -3 3 1 10 9 15 21 14 24 12 10 -5z
m82 -33 c13 -5 15 -11 8 -22 -5 -8 -10 -83 -10 -167 0 -144 -1 -153 -22 -173
-12 -11 -25 -20 -30 -20 -4 0 -8 88 -8 195 l0 195 23 -1 c12 0 30 -3 39 -7z
m158 -567 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z"
          />
          <path
            d="M3119 3989 c-19 -19 -20 -28 -13 -110 10 -136 15 -153 47 -159 21 -4
24 -8 17 -20 -11 -18 -13 -599 -2 -616 5 -7 46 -9 125 -6 l117 5 0 318 c0 206
4 320 10 324 6 4 24 3 41 0 26 -6 28 -9 17 -22 -16 -20 -19 -607 -3 -621 6 -4
74 -6 153 -4 l142 4 0 118 c0 119 11 156 30 105 6 -14 10 -50 10 -78 0 -81 16
-125 48 -134 15 -5 109 -8 209 -8 177 0 182 1 207 24 31 29 44 92 48 224 1 67
-2 101 -11 110 -17 17 -173 16 -194 -1 -9 -7 -19 -27 -22 -43 -7 -33 -32 -62
-46 -54 -12 8 -12 372 0 379 5 3 22 3 39 0 28 -6 29 -7 17 -31 -14 -28 -6 -69
14 -67 6 1 54 2 106 3 l95 1 0 82 c0 133 -20 244 -48 271 -23 21 -31 22 -202
22 -199 0 -213 -4 -255 -76 -18 -31 -20 -57 -25 -301 -3 -146 -7 -268 -10
-271 -3 -2 -19 -7 -37 -10 l-32 -6 -5 282 c-6 359 -6 358 -58 376 -47 16 -169
14 -195 -4 -18 -13 -22 -13 -33 0 -10 12 -40 15 -146 15 -122 0 -136 -2 -155
-21z m281 -223 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z
m630 0 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m212
-618 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z"
          />
          <path
            d="M4471 3988 c-86 -58 -93 -99 -89 -502 3 -329 6 -347 54 -387 24 -19
408 -26 443 -7 51 28 62 57 71 189 5 89 5 126 -4 131 -6 4 -54 8 -106 8 -102
0 -140 -13 -140 -49 0 -10 -8 -25 -17 -33 -41 -35 -43 -33 -43 46 l0 76 155 0
155 0 0 180 c0 213 -14 310 -48 343 -22 21 -33 22 -210 25 -182 3 -188 2 -221
-20z m159 -191 c0 -2 -9 -12 -20 -22 -24 -21 -27 -14 -4 9 15 15 24 20 24 13z
m79 -86 c-11 -22 -31 -46 -44 -55 -23 -15 -23 -15 -27 35 -1 28 -1 53 1 55 2
2 24 4 48 4 l43 0 -21 -39z m-89 -215 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12
-4 4 13 16 21 21 21 13z m255 -345 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14
17 26 13 15 -4z"
          />
          <path
            d="M4996 3991 c-21 -23 -22 -36 -12 -146 11 -120 13 -125 42 -125 22 0
25 -3 18 -17 -5 -10 -10 -171 -12 -358 l-3 -339 -27 -29 c-26 -28 -27 -31 -27
-147 0 -67 4 -123 10 -127 6 -5 87 -7 181 -5 l171 4 6 131 c3 73 3 135 0 140
-2 4 -13 2 -24 -4 -12 -6 -22 -9 -24 -7 -7 8 -11 208 -3 208 5 0 18 -21 28
-46 l20 -46 90 4 c82 3 92 5 117 31 l28 27 3 357 c2 293 0 365 -12 398 -8 22
-17 52 -20 66 -3 14 -17 31 -31 37 -38 17 -166 15 -192 -3 -18 -13 -22 -13
-33 0 -10 12 -40 15 -145 15 -118 0 -134 -2 -149 -19z m301 -63 c-3 -7 -5 -2
-5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-33 -173 c-4 -8 -11 -15 -16 -15 -6
0 -5 6 2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z m78 -33 c13 -5 15 -11 8 -22 -5
-8 -10 -83 -10 -166 0 -134 -2 -154 -19 -175 -11 -13 -22 -22 -24 -19 -4 4
-11 347 -8 383 1 9 30 8 53 -1z m-72 -516 c0 -2 -8 -10 -17 -17 -16 -13 -17
-12 -4 4 13 16 21 21 21 13z m245 -56 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10
3 6 11 10 16 10 6 0 7 -4 4 -10z"
          />
          <path
            d="M3830 2780 c-58 -12 -87 -27 -130 -66 -63 -57 -80 -99 -80 -194 0
-91 14 -132 56 -172 59 -55 224 -85 297 -55 82 35 147 130 147 215 0 53 -43
178 -73 210 -44 49 -146 78 -217 62z m194 -391 c-10 -11 -28 -25 -39 -31 -11
-6 -5 5 15 25 37 37 57 42 24 6z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
